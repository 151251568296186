.root {
    display: none;
}

@media (--snow-tablet) {
    .root {
        display: block;
        height: 100%;
        width: 100%;
    }

    .link {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        position: relative;
        text-decoration: none;
    }

    .image {
        border-radius: 6px;
        height: 100%;
        object-fit: cover;
        width: 100%;
        max-width: 38px;
    }

    .stickedImage {
        max-width: 38px;
    }

    .title {
        display: none;
        padding: 0 8px;
    }

    .icon {
        height: 20px;
        width: 20px;
    }
}

@media (--snow-desktop) {
    .image {
        max-width: 58px;
    }

    .icon {
        height: 24px;
        width: 24px;
    }

    .title {
        color: #fff;
        display: inline;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.01em;
        line-height: 13px;
    }

    .icon + .title {
        margin-top: 2px;
    }
}
